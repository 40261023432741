<template>
  <div class="req-temp-container">
    <vs-card class="card mt-5 legals ds-wrapper" id="direct-debit-legals">
      <div slot="header" class="py-4 pr-6 pl-8">
        <h3 class="font-normal mb-0 head-text">Fees</h3>
      </div>
      <div class="pt-6 px-8 pb-10">
        <div class="editable-field">
          <label class="input-label">Dishonour fee</label>
          <template  v-if="editDishonourFee">
            <money name="dishonourFee" id="dishonourFee" v-bind="money" v-model="dishonourFee"></money><br>
            <span v-if="errorMsg"><small class="text-danger">{{ errorMsg }}</small></span>
            <div class="flex flex-row gap-x-6 mt-4">
              <vs-button color="primary" @click="saveDishonourFee" size="small" >Save</vs-button>
              <vs-button type="flat" @click="toggleEdit" size="small" >Cancel</vs-button>
            </div>
          </template>
          <template v-else>
          <p> {{ dishonourFeeDisplay }}</p>
          <vs-icon type="flat" class="icon-gray edit-icon material-icons-outlined" icon="edit" icon-size="small" @click.prevent="toggleEdit()" />
          </template>
        </div>
      </div>
    </vs-card>
    <vs-card class="card mt-5 legals">
      <div slot="header">
        <h3 class="font-normal mb-0 text-base sm:text-lg md:text-xl lg:text-2xl">Customer Direct Debit terms & conditions</h3>
      </div>
      <div>
        <p class="mb-10">Create the T&Cs customers must accept on the payment page when making payments using direct debit.</p>
        <div class="legals__item">
          <div class="mb-6 flex flex-row items-center" :class="{ 'justify-end': customerTermsAndConditions.length > 0 }">
            <vs-button v-round class="w-2/12" v-if="customerTermsAndConditions.length === 0" color="primary" icon="add" @click="createLegal('CUSTOMER')">
              New document
            </vs-button>
            <vs-button v-else class="only-border-btn" v-round size="medium" @click="createLegal('CUSTOMER', true)">
              <plus-icon size="1.5x" class="button-icon"></plus-icon>New version
            </vs-button>
          </div>

          <vs-table :data="customerTermsAndConditions" v-if="customerTermsAndConditions.length > 0">
            <template slot="thead">
              <vs-th>Document name</vs-th>
              <vs-th>Date created</vs-th>
              <vs-th>Version</vs-th>
              <vs-th></vs-th>
              <vs-th></vs-th>
            </template>
            <template slot-scope="{ data }">
              <vs-tr :key="indextr" v-for="(tr, indextr) in data">
                <vs-td class="capitalize" :data="data[indextr].header">
                  {{ data[indextr].documentName }}
                </vs-td>
                <vs-td :data="data[indextr].createdAt">
                  {{ formatScheduleDate(data[indextr].createdAt) }}
                </vs-td>
                <vs-td :data="data[indextr].id">
                  {{ data[indextr].version }}
                </vs-td>
                <vs-td :data="data[indextr].id">
                  <span class="active-badge" v-if="indextr === 0">Active</span>
                </vs-td>
                <vs-td :data="data[indextr].id" class="view-btn">
                  <a class="underline" @click="viewLegal(tr._id)">View</a>
                </vs-td>
              </vs-tr>
            </template>
          </vs-table>
        </div>
      </div>
    </vs-card>

    <vs-card class="card mt-5 legals">
      <div slot="header">
        <h3 class="font-normal mb-0 text-base sm:text-lg md:text-xl lg:text-2xl">Merchant NPP terms & conditions</h3>
      </div>
      <div>
        <p class="mb-6">Create the T&Cs merchants must accept when enabling NPP on their account.</p>
        <div class="legals__item">
          <div class="mb-6 flex flex-row items-center" :class="{ 'justify-end mb-6': merchantTermsAndConditions.length > 0 }">
            <vs-button v-round class="w-2/12" v-if="merchantTermsAndConditions.length === 0" color="primary" icon="add" @click="createLegal('MERCHANT')">
              New document
            </vs-button>
            <vs-button v-else class="only-border-btn" v-round size="medium" @click="createLegal('MERCHANT', true)">
              <plus-icon size="1.5x" class="button-icon"></plus-icon>New version
            </vs-button>
          </div>
          <vs-table :data="merchantTermsAndConditions" v-if="merchantTermsAndConditions.length > 0">
            <template slot="thead">
              <vs-th class="legals-th">Document name</vs-th>
              <vs-th class="legals-th">Date created</vs-th>
              <vs-th class="legals-th">Version</vs-th>
              <vs-th></vs-th>
              <vs-th></vs-th>
            </template>
            <template slot-scope="{ data }">
              <vs-tr :key="indextr" v-for="(tr, indextr) in data">
                <vs-td class="capitalize" :data="data[indextr].header">
                  {{ data[indextr].documentName }}
                </vs-td>
                <vs-td :data="data[indextr].createdAt">
                  {{ formatScheduleDate(data[indextr].createdAt) }}
                </vs-td>
                <vs-td :data="data[indextr].id">
                  {{ data[indextr].version }}
                </vs-td>
                <vs-td :data="data[indextr].id">
                  <span class="active-badge" v-if="indextr === 0">Active</span>
                </vs-td>
                <vs-td :data="data[indextr].id" class="view-btn">
                  <a class="underline" @click="viewLegal(tr._id)">View</a>
                </vs-td>
              </vs-tr>
            </template>
          </vs-table>
        </div>
      </div>
    </vs-card>
  </div>
</template>
<script>
import { mapActions, mapState } from "vuex";
import _ from "lodash";
import moment from "moment";
import { PlusIcon } from "vue-feather-icons";
import { Money } from "v-money";

export default {
  components: {
    Money,
    PlusIcon,
  },
  data() {
    return {
      money: {
        precision: 2,
        prefix: "$ ",
      },
      dishonourFee: 0,
      editDishonourFee: false,
      dishonourFeeDisplay: 0,
      errorMsg: ""
    };
  },
  methods: {
    ...mapActions("legals", ["fetchAllLegals"]),
    ...mapActions("setting", ["fetchSetting", "saveSetting"]),

    formatScheduleDate(date) {
      return moment(date).format("DD MMM YYYY");
    },

    createLegal(user, newVersion = false) {
      this.$router.push({
        name: "new-payment-platform-legals-editor",
        query: { user, newVersion },
      });
    },

    getLegals() {
      this.$vs.loading();
      this.fetchAllLegals("DIRECT_DEBIT").then((result) => {
        this.$vs.loading.close();
      }).catch((ex) => {
        this.$vs.loading.close();
        this.showToastMessage(ex.response.data.title, ex.response.data.message, "error");
      });
    },

    viewLegal(id) {
     const routeData = this.$router.resolve({
        name: "terms-view",
        params: { id },
        query: { type: "DIRECT_DEBIT" },
      });
      window.open(routeData.href, '_blank');
    },

    toggleEdit() {
      this.editDishonourFee = !this.editDishonourFee;
      this.dishonourFee = parseFloat(this.dishonourFee).toFixed(2);
      this.errorMsg = "";
    },

    async saveDishonourFee() {
      if (this.dishonourFee == 0) {
        this.errorMsg = "The field dishonour fee is required";
        return;
      }
      const data = {
        settingName: "dishonourFee",
        settingValue: this.dishonourFee
      };

      this.$vs.loading();
      await this.saveSetting(data).then((response) => {
        this.editDishonourFee = false;
        this.dishonourFee = response.data.data.rawAmount;
        this.dishonourFeeDisplay = response.data.data.value;
        this.$vs.loading.close();
      }).catch((ex) => {
        this.$vs.loading.close();
        this.showToastMessage(ex.response.data.title, ex.response.data.message, "error");
      })
    },

    async getDishonourFee() {
      this.$vs.loading();
      await this.fetchSetting("dishonourFee").then((response) => {
        this.dishonourFee = response.data.data.rawAmount;
        this.dishonourFeeDisplay = response.data.data.value;
        this.$vs.loading.close();
      }).catch((ex) => {
        this.$vs.loading.close();
        this.showToastMessage(ex.response.data.title, ex.response.data.message, "error");
      });
    }
  },
  created() {
    this.getDishonourFee();
    this.getLegals();
  },
  computed: {
    ...mapState("legals", ["legals"]),
    merchantTermsAndConditions() {
      const terms = this.legals.filter((legal) => legal.user === "MERCHANT");
      return _.sortBy(terms, ["version"]).reverse();
    },

    customerTermsAndConditions() {
      const terms = this.legals.filter((legal) => legal.user === "CUSTOMER");
      return _.sortBy(terms, ["version"]).reverse();
    },
  },

  watch: {
    dishonourFee: function(val) {
      if (val) {
        this.errorMsg = "";
      }
    }
  }
};
</script>